module.exports = {
  siteTitle: "Cybercow",
  manifestName: "Aerial",
  manifestShortName: "Portfolio", // max 12 characters
  manifestStartUrl: "/",
  manifestBackgroundColor: "#663399",
  manifestThemeColor: "#663399",
  manifestDisplay: "standalone",
  manifestIcon: "src/assets/img/sacred-cow.svg",
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  // social

  authorName: "Daniel Lindh",
  authorHeading:
    "Unicorn Breeder - Business Developer - System Architect - System Developer - System Operator",
  authorSocialLinks: [
    {
      icon: "fa-linkedin",
      name: "Linkedin",
      url: "https://www.linkedin.com/in/daniel-lindh-entrepreneur"
    },
    {
      icon: "fa-github",
      name: "Github",
      url: "https://github.com/arlukin"
    },
    {
      icon: "fa-envelope-o",
      name: "Email",
      url: "mailto:my-first-name@cybercow.se"
    }
  ]
};
